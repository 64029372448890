<template>
    <div class="realfriend-post-make" @scroll="onScroll">
        <StackRouterHeaderBar :class="{ 'show-title': showTitle }" :title="$translate('MAKE')" :showTitle="showTitle" />
        <h2 class="title m-b-8" v-html="$translate('REALFRIEND_MAKE')" />
        <!--  snackbar  -->
        <div class="snack-container">
            <div class="rectangle" />
            <div class="snackbar">
                <p>소개팅 성사 시 <span class="f-bold">10,000 밥 포인트</span> 지급</p>
                <div class="badge1" @click="fireModal">
                    <i class="material-icons">info</i>
                    <span style="font-size: 12px">밥포인트</span>
                </div>
            </div>
        </div>

        <section class="section brief-plan">
            <span class="flex m-t-16">
                <p class="section-title m-b-16" v-html="$translate('FRIEND_GENDER')" />
                <div class="gender">
                    <div
                        :class="{ selected: gender.selected }"
                        v-for="gender in genderOptions"
                        :key="gender.title"
                        @click="onSelectGender(gender)"
                    >
                        {{ gender.title }}
                    </div>
                </div>
            </span>
            <span v-for="item in categoryState" :key="item.id" class="age-state">
                <p class="f-regular f-16 c-text-lighter" v-html="$translate(item.question)" />
                <p
                    class="f-medium c-primary f-16"
                    v-html="item.answer || $translate('PLEASE_SELECT')"
                    @click="showModalSelect(item)"
                />
            </span>
        </section>

        <section class="section explain-plan">
            <div class="explain-title m-b-32">
                <p class="section-title m-b-12" v-html="$translate('TITLE')" />
                <TextareaWithX
                    class="input-title"
                    v-model="title"
                    :placeholder="$translate('FILL_IN_TITLE')"
                    @focus="onFocus"
                    @blur="onBlur"
                    @input="onInput"
                />
            </div>
            <div class="explain-content m-b-34">
                <div class="title-content">
                    <div>
                        <span class="section-title m-b-12" v-html="$translate('CONTENT_100')" />
                        <span style="font-weight: lighter"> (100자 이상)</span>
                    </div>
                    <span v-if="textLen" class="text-len" :style="{ color: textLen >= 100 ? '#8A74FF' : ' #FF3D6B' }">{{
                        `글자 수: ${textLen}`
                    }}</span>
                </div>
                <TextareaWithX
                    class="input-content"
                    v-model="content"
                    :placeholder="$translate('INTRODUCE_FRIEND')"
                    @focus="onFocus"
                    @blur="onBlur"
                    @input="onInput"
                />
                <ul class="description flex m-t-8">
                    <li :key="idx" v-for="idx in 3">
                        <p class="dot m-r-8" />
                        <p v-html="$translate(`REALFRIEND_MEET_POST_MAKE_DESCRIPTION_${idx}`)" />
                    </li>
                </ul>
                <!--  사진 입력 인풋창 컴포넌트로 만들어놓자  -->
                <div class="explain-photo">
                    <PhotoInputArea
                        :from="`realfriend`"
                        :isOption="true"
                        @submitPhoto="updatePhoto"
                        @clearPhoto="clearPhoto"
                    />
                </div>
            </div>
        </section>

        <section class="introduction-container">
            <p class="section-title introduction m-b-12" v-html="$translate('PROHIBIT_ABUSING')" />
            <ul class="prohibit flex m-t-8">
                <li v-for="idx in 2" :key="idx">
                    <p class="dot m-r-8" />
                    <p v-html="$translate(`ABUSING_DESCRIPTION_${idx}`)" />
                </li>
            </ul>
        </section>

        <section class="introduction-container">
            <p class="section-title introduction m-b-12" v-html="$translate('REALFRIEND_HOWTOUSE')" />
            <ul class="desc flex m-t-8">
                <li v-for="idx in 6" :key="idx">
                    <p class="dot m-r-8" />
                    <p v-html="$translate(`REALFRIEND_HOWTOUSE_${idx}`)" />
                </li>
            </ul>
        </section>
        <BottomButton
            v-show="showBottomButton"
            :label="$translate('POST_FEED')"
            :disabled="buttonDisabled"
            @click="submit"
        />
    </div>
</template>

<script>
// import commonService from '@/services/common'
import realfriendMeetingService from '@/services/realfriend-meeting'
import PhotoInputArea from '@/components/app/PhotoInputArea'

export default {
    name: 'RealFriendMeetingPostMakePage',
    components: {
        PhotoInputArea,
    },
    props: {
        state: Array,
    },
    data: () => ({
        showTitle: false,
        isSnackbarOpened: true,
        ageOptions: [],
        stateOptions: [],
        categoryState: [],
        genderOptions: [],
        title: '',
        content: '',
        gender: 0,
        stateId: null,
        age: '',
        customPhoto: {
            blob: null,
            url: null,
        },
        timer: null,
        showBottomButton: true,
        isTyping: false,
    }),
    created() {
        this.init()
    },
    computed: {
        textLen() {
            return this.content.length || 0
        },
        buttonDisabled() {
            return !(this.gender !== null && this.stateId && this.title && this.textLen >= 100 && this.age)
        },
    },
    methods: {
        onScroll(event) {
            this.showTitle = event.target.scrollTop > 52
        },
        async init() {
            this.stateOptions = this.state
            this.categoryState = [
                {
                    id: 'friend-state',
                    question: 'FRIEND_STATE',
                    answer: null,
                },
                {
                    id: 'friend-age',
                    question: 'FRIEND_AGE',
                    answer: null,
                },
            ]

            this.genderOptions = [
                {
                    title: this.$translate('MALE'),
                    genderId: 0,
                    selected: true,
                },
                {
                    title: this.$translate('FEMALE'),
                    genderId: 1,
                    selected: false,
                },
            ]

            for (let i = 1; i <= 20; i++) {
                const option = { id: i, name: i + 19 }
                this.ageOptions.push(option)
            }
        },
        async submit() {
            const isCheckAbusing = localStorage.getItem('banAbusing')

            // 어뷰징 방지 확인 시 게시글 서버로 전송
            if (!isCheckAbusing) {
                const idx = await this.$modal.custom({
                    component: 'ModalBanAbusing',
                    options: {
                        title: '어뷰징',
                        cards: [
                            {
                                label: 'WARNING_MODAL_CARD_1',
                                icon: 'content_paste_off',
                            },
                            {
                                label: 'WARNING_MODAL_CARD_2',
                                icon: 'cancel_schedule_send',
                            },
                        ],
                        body: this.$translate('WARNING_MODAL'),
                        buttons: [
                            {
                                label: 'CANCEL',
                                class: 'btn-default',
                            },
                            {
                                label: 'AGREE',
                                class: 'btn-primary',
                            },
                        ],
                    },
                })
                if (idx === 1) {
                    localStorage.setItem('banAbusing', true)
                } else return // 확인 버튼 미클릭 시 게시글 전송 불가
            }

            await this.submitPost()

            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'IntroduceMyFriend',
                    option: {
                        funnel: 'click_create_post',
                    },
                },
            })
        },
        async submitPost() {
            try {
                this.$loading(true)
                const payload = {
                    user_id: Number(this.$store.getters.me.id),
                    gender: Number(this.gender),
                    age: Number(this.age),
                    state_id: Number(this.stateId),
                    title: this.title,
                    content: this.content,
                }
                if (this.customPhoto.blob) payload.photo = this.customPhoto.blob
                const { msg } = await realfriendMeetingService.create(this.preparePayload(payload))
                await this.$store.dispatch('loadRealfriendMeetingPosts', { userId: this.$store.getters.me.id })
                this.$toast.success(msg)
            } catch (e) {
                this.$toast.error(e.data.msg)
            } finally {
                this.$loading(false)
                this.$stackRouter.pop()
            }
        },
        preparePayload(payload) {
            const formData = new FormData()
            Object.entries(payload).forEach(([key, value]) => {
                formData.append(key, value)
            })

            return formData
        },
        fireModal() {
            this.$modal.custom({
                component: 'ModalRicePoint',
            })
        },
        showModalSelect(item) {
            this.$modal
                .custom({
                    component: 'ModalSelect',
                    options: {
                        id: item.id,
                        title: item.question,
                        options: item.id === 'friend-state' ? this.stateOptions : this.ageOptions,
                    },
                })
                .then(res => {
                    if (typeof res.name === 'string') {
                        this.categoryState[0].answer = res.name
                        this.stateId = res.id
                    } else if (typeof res.name === 'number') {
                        this.categoryState[1].answer = res.name
                        this.age = res.name
                    }
                })
        },
        updatePhoto(photo) {
            this.customPhoto = photo
        },
        clearPhoto() {
            this.customPhoto = {
                blob: null,
                url: null,
            }
        },
        onSelectGender(gender) {
            this.gender = gender.genderId
            this.genderOptions.forEach(gender => {
                gender.selected = this.gender === gender.genderId
            })
        },
        onFocus() {
            this.showBottomButton = false
        },
        onBlur() {
            this.showBottomButton = true
        },
        onInput() {
            this.showBottomButton = false
            if (this.timer) {
                clearTimeout(this.timer)
                this.isTyping = false
            }
            this.timer = setTimeout(() => {
                this.isTyping = true
            }, 1500)
        },
    },
    watch: {
        isTyping: function (newVal) {
            if (!newVal) {
                this.showBottomButton = false
            } else {
                this.showBottomButton = true
            }
        },
    },
}
</script>

<style scoped lang="scss">
::v-deep .stack-router-header-bar {
    &.show-title {
        background-color: white;
        border-bottom: solid 1px $grey-03;
    }
}
.realfriend-post-make {
    $section-side-padding: 16px;
    $bottom-button-height: 80px;

    padding-bottom: $bottom-button-height;
    line-height: normal;
    letter-spacing: -0.2px;

    .title {
        padding: 0 16px;
        color: black;
    }
    .snack-container {
        position: relative;
        width: 100vw;

        .rectangle {
            background-color: $grey-09;
            position: absolute;
            z-index: -1;
            left: 10%;
            width: 30px;
            height: 30px;
            transform: rotate(45deg);
        }
        .snackbar {
            position: static;
            max-width: 93vw;
            min-width: 340px;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 12px;
            font-size: 14px;
            box-shadow: none;

            p,
            span {
                font-size: 14px;
            }

            .badge1 {
                width: 84px;
                height: 24px;
                background-color: $white;
                border-radius: 12px;
                padding: 3px 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                white-space: nowrap;

                i {
                    margin: 0 3px 0 0;
                    font-size: 16px;
                }

                span {
                    color: $grey-08;
                    @include f-bold;
                }
            }
        }
    }
    .section {
        padding: 50px $section-side-padding 16px $section-side-padding;

        .section-title {
            @include f-medium;
        }

        &.brief-plan,
        &.explain-plan {
            border-bottom: 1px solid $grey-02;
        }

        .age-state {
            margin: 32px 0;
            @include flex-row;
            @include flex-between;
        }

        .gender {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 8px;

            div {
                width: auto;
                height: 40px;
                color: $grey-05;
                padding: 9px 0;
                border-radius: 10px;
                border: 1px solid $grey-03;

                @include center;
                @include flex-row;

                &.selected {
                    border: solid 1px $purple-primary;
                    color: $purple-primary;

                    @include f-medium;
                }
            }
        }

        .input-title {
            height: 48px;
            padding: 12px 16px;

            ::v-deep textarea {
                line-height: 22px;
            }
        }

        .title-content {
            display: flex;
            justify-content: space-between;
            margin-bottom: 12px;

            .text-len {
                font-size: 13px;
                @include f-bold;
            }
        }

        .input-content {
            height: 200px;
        }
    }

    .introduction-container {
        padding: 24px 16px;
        border-bottom: 1px solid $grey-02;

        .introduction {
            color: #000000;
            font-size: 16px;
        }
    }

    .description {
        background-color: $grey-01;
        padding: 14px;
        font-size: 12px;
        border-radius: 10px;
    }
    li {
        display: flex;
        color: black;
        font-size: 12px;

        &:not(:last-child) {
            margin-bottom: 8px;
        }
    }

    .dot {
        margin-top: 6px;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: $grey-07;

        @include flex-wrap;
    }

    .bottom-button {
        position: absolute;
    }
}
</style>
